.css-1ygcj2i-MuiTableCell-root{
    padding: 5px 0px 15px 8px !important;
    border: none !important;
    line-height: 1 !important;
    color: #777 !important;
    font-family: "Manrope", sans-serif !important ;
    font-size: 12px !important;
    /* width: 10% !important; */
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root{
    box-shadow: none !important;
    border: none !important;
    width: 100% !important;
    overflow-x: scroll !important;
}
.css-1ex1afd-MuiTableCell-root{
    padding: 13px 9px !important;
    font-family: General Sans!important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: black;
    background-color: #f7f7f7 !important;
    margin-top: 1em !important;
    /* border-radius: 7px !important; */
    

}
.css-1q1u3t4-MuiTableRow-root{
    border-radius: 7px !important;
    /* border: 0.5px solid #747eef !important; */
}
.div-row-type-sales{
    display: flex;
    align-items: center;
    justify-content: space-between;
}