.section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 100vh;
    width: 95%;
  }
  .marginclass{
    margin-left: 2.2em;
  }
  .btn-card{
    display: none;
}
  .left {
    flex: 1;
    padding-right: 20px;
  }
  
  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .right img {
    max-width: 100%; /* Ensure the image doesn't exceed its container's width */
    align-items: center;
  }
  .imageEdit{
    position: absolute;
    width: 40%;
    height:100vh;
  object-fit: contain; right: 0;
   top: 30px
  }
.classadd{
    right: 20%;
  }

  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .marginclass{
      margin-left: 1em;
    }
    .section {
     flex-direction: column-reverse;
     margin-top: 20px;
    }
    .imageEdit{
        width: 100%;
        height: 300px;
        top: 2.5%;
    }
    .left,
    .right {
      width: 100%; /* Make both sides take up full width on small screens */
      padding: 0; /* Remove padding on small screens */
      text-align: center; /* Center align text on small screens */
    }
  
    .right {
      justify-content: center; /* Center the phone image on small screens */
    }.buttondiv{
        
    text-align: center;
    justify-content: center;
    display: flex;
    }
    .btn-card{
        display: block;
    }.card-name-btn{
        display: none;
    }.classadd{
        display: none;
    }
  }
  @media (max-width: 550px) {
    .sm\:p-5 {
      
        margin-top: 32px !important;
    }
}
  