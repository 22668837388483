.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1000;
  }
  
  .modal-overlay.open {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
  
  .modal-overlay.close {
    opacity: 0;
    visibility: hidden;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 55%;
    width: 90%;
    position: relative;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
    z-index: 1001;
  }
  
  .modal-content.slide-in {
    transform: translateY(0);
    opacity: 1;
    background-color: white;
    padding: 30px 20px;
    border-radius: 8px;
    /* width: 60% */
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
  }
  
  .modal-content.slide-out {
    transform: translateY(-100%);
    opacity: 0;
    /* width: 60% */
  }
  
  .close-button {
    /* position: absolute;
    top: 10px;
    right: 10px;
    background: transparent; */
    border: none;
    font-size: 20px;
    cursor: pointer;
    width: 50%;
    text-align: end;
    display: flex;
    justify-content: end;
  }
  .row-modal-popup{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .h2-heading-main{
    width: 50%;
  }
  .addtionwidth{
width: 100% !important;
  }
  .margin-bottom{
    margin-bottom: 10px;
  }