.row-div-admin-dashboard{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#notification-clases{
    width: 55% !important;
  
}
.bell-div-css{
    width: 49%;
    text-align: end;
    display: flex;
    justify-content: end;
}
.width-asd{
    width: 50% !important;
}
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  