.div-singup-btn-login{
    margin-top: 3em;
    display: flex;
    align-items: center;
    gap: 10px;
}
.image-div-class-not-verify{
    object-fit: contain;
    height: 6em;
    width: 100%;
    margin-bottom: 50px;
}