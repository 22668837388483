.chart-container {
    width: 100%;
    margin: auto;
    background-color: white;
    padding: 15px 15px;
    border-radius: 11px;
    margin-top: 0.7em;
    box-shadow: 0px 0px 2px 0px #afafaf;
}
.chart-colm-div{
    width: 70%;
    display: flex;
    justify-content: end;
}
.chart-colm-div-heading{
    width: 70%;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
}
.row-main-chart-div{
    display: flex;
    justify-content: space-between;
}
.buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    /* margin-bottom: 20px; */
}

button {
    color: white;
    padding: 0px 14px;
    margin: 0 5px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    font-size: 0.8em;
    font-family: General Sans!important;
    border-radius: 5px;
    
}

button.active {
    background-color: #747EEF;
    color: white;
    padding: 0px 14px;
    font-size: 0.8em;
    font-family: General Sans!important;
    border-radius: 5px;
}
.mainbutton{
    font-size: 0.8em;
    font-family: General Sans!important;
    color: black;
    border-radius: 5px;
    padding: 0px 14px;
    border: 1px solid black;
    background-color: white !important;
}
.div-chart-div{
    margin-top: 1em;
   
}
button:focus {
    outline: none;
}
