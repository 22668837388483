.supportcom-icon{
    background-color: #f7f7f7;
    padding: 20px 1em;
    border-radius: 12px;
    border: 1px solid #747eef;
    width: 100%;    
}
.image-support-div-img{
    height: 4em;
    width: 100%;
    object-fit: contain;
}
.h5-heaidng-support{
    font-family: "Manrope", sans-serif;
    color: black;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    padding-top: 0.5em;
}
.dic-contact-suport{
width: 80%;
}
.supportcom-icon-conact{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f7f7;
    padding: 10px 0.5em;
    border-radius: 12px;
    border: 1px solid #747eef;
    width: 100%;
    gap: 10px;
}
.image-support-div-img-conact-seupprot{
    height: 4em;
    width: 20%;
    object-fit: contain;
}