.service-timing {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 600px;
    margin: auto;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  label {
    display: flex;
    align-items: center;
  }
  
  .ant-switch {
    margin-left: 10px;
  }
  
  .specific-provider-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .provider-selection {
    margin-top: 20px;
  }

  
  .btninactive {
    background-color: #747EEF !important;
    cursor: not-allowed;
    color: white !important;
    opacity: 0.7;
    font-size: 14px !important;
    padding: 5px 13px !important;
    border-radius: 6px;
    height: 2.5em;
  }
  .span-seleceted-servicetiming{
    background: #747EEF;
    padding: 7px;
    font-size: 12px;
    border-radius: 3px;
    color: white;
    font-family: 'General Sans';
    margin-left: 2px;
  }
  .btnactive {
    background-color: #747EEF;
    color: white;
    font-size: 14px;
    padding: 5px 13px !important;
    border-radius: 6px;
    height: 2.5em;
  }
  
  .timing-table {
    margin-top: 40px;
  }


button:not(:disabled) {
    cursor: pointer;
    /* background: #747EEF; */
}
/* :where(.css-dev-only-do-not-override-m4timi).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover{
    background-color: white !important;
    color:#747EEF !important
} */
  .day-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .day-row span {
    flex: 1;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60vh;
    overflow-x: scroll;
    padding: 20px 20px;
    border-radius: 15px;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
  }
  
  .popup-content div {
    margin-bottom: 10px;
  }
  .width-settimg{
    width: 78%;
  }
  .padding-right-property{
    padding-right: 25px;
    display: flex;
    gap: 20px;
  }
  .addtionwidth-setting{
    width: 100% !important;
  }
  .react-date-picker{
    width: 100% !important;
  }
  .react-date-picker__wrapper{
    border: none !important;
    border-bottom: 1px solid black !important;
  }
  :where(.css-1x0dypw).ant-btn-sm{
    background-color: #747EEF !important;
  }