.label-popup-seller{
    /* font-family: "Manrope", sans-serif; */
    font-weight: 500;
    font-size: 16px;
}
.label-popup-seller input::placeholder{
    color: #c3c3c3;
    font-weight: 400;
    font-size: 16px;
  }
.gapmargin input::placeholder{
    color: #c3c3c3;
    font-weight: 400;
    font-size: 16px;
  }
.timing-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6px;
    gap: 26px;
  }
  :where(.css-dev-only-do-not-override-m4timi).ant-picker-outlined.ant-picker-disabled input[disabledenabled]{
    margin-bottom: 0px !important;
  }
  :where(.css-dev-only-do-not-override-m4timi).ant-picker .ant-picker-input >input{
    margin-bottom: 0px !important;
    font-size: 12px;
  }
  :where(.css-dev-only-do-not-override-m4timi).ant-picker .ant-picker-input >input::placeholder{
    font-size: 12px;
  }
  .row-poup{
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
  }
  .add-css-styling-cancel-div{
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    padding: 13px 1em;
    border-radius: 8px;
    border: 1px solid #747eef;
    gap: 10px;
    margin-bottom: 13px;
  }
  .span-tag-popup{
    position: absolute;
    top: 64%;
    right: 3%;
    font-size: 16px;
  }
  .addtion-spantag{
    position:relative !important
  }
  :where(.css-dev-only-do-not-override-m4timi).ant-btn-sm{
    background-color: #747eef !important;
  }
  .custom-time-picker .ant-picker-ok{
    background-color: #747eef !important;
  }
  .custom-time-picker .ant-picker-ok:hover {
    background-color: #747eef !important; 
   }
   :where(.css-dev-only-do-not-override-1x0dypw).ant-picker .ant-picker-input >input:placeholder-shown{
    margin: 0;
   }
   :where(.css-dev-only-do-not-override-1x0dypw).ant-picker .ant-picker-input >input{
    margin: 0;
   }
   :where(.css-dev-only-do-not-override-1x0dypw).ant-btn-sm{
    background-color: #747eef !important; 
   }
   :where(.css-1x0dypw).ant-picker .ant-picker-input >input{
    margin: 0 !important;
   }