.main-pending-colm{
    width: 100%;
    margin: auto;
    background-color: #f7f7f7;
    padding: 15px 15px;
    border-radius: 11px;
    margin-top: 0.7em;
    border: 0.5px solid #747eef;
}
.paragraph-text-p{
    font-family: "Manrope", sans-serif;
    font-weight: 300;
    font-size: 0.8em;
}
.threedor-icon{
    color: #c0c0c0;
    font-size: 20px;
    position: absolute;
    top: 0;
    right: -6px;
}
.popup-pending-appliction{
    display: flex;
    justify-content: space-between;
    gap: 18px;
}
.div-left-popup{
    text-align: left;
    width: 60%;
   
}
.extra-div-width{
    width: 40% !important;
}
.popup-inputfeild-application{
    background-color: #f7f7f7;
    padding: 13px 9px;
    border-radius: 7px;
    margin-top: 0.6em;
    border: 0.5px solid #747eef;
}
.customer-pending{
    background-color: #f7f7f7;
    padding: 8px 9px;
    border-radius: 7px;
    margin-top: 0.6em;
    border: 0.5px solid #747eef;
}
.vat-number-api{
    font-weight: 200;
    font-size: 13px;
    width: 50%;
}
.popup-inputfeild-application{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        font-weight: 400;
        background:#f7f7f7;
        opacity: 1;
}
.valt-number-input-feild{
    width: 50%;
    text-align: end;
}

.div-verifecation-downlood{
    position: relative;
    background-color: #f7f7f7;
    padding: 10px 9px;
    border-radius: 7px;
    margin-top: 0.6em;
    border: 0.5px solid #747eef;
    height: 11.5em;
}
.icon-downlood-da{
    color: white;
    font-size: 13px;
}
.icon-downlood-verfication{
    background-color: #747eef;
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    right: 10px;
    top: 10px;

}
.margin-top-class{
    margin-top: 14px;
}
/* Popup.css */

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .popup-container {
    background: white;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.5s ease-in-out;
  }
  
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .close-icon {
    cursor: pointer;
    font-size: 24px;
  }
  .popup-overlay.active {
    opacity: 1;
  }
  
  /* .popup-container {
    background: white;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    padding: 20px;
    transform: translateY(-50px);
    transition: transform 0.3s ease-in-out;
  } */
  
  .popup-container.active {
    transform: translateY(0);
  }
  .popup-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .popup-content {
    display: flex;
    gap: 20px;
  }
  
  .left-section, .right-section {
    flex: 1;
  }
  .section-title {
    margin-top: 20px;
  }
  
  .signatory-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .profile-image {
    width: 10%;
    margin-right: 10px;
  }
  
  .signatory-details, .signatory-position {
    flex: 1;
  }
  
  .document-container {
    position: relative;
  }
  
  .document-viewer {
    width: 100%;
    height: 400px;
    border: none;
  }
  
  .download-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #000;
  }
  
  .popup-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .image-pending-css{
   box-shadow:0px 0px 3px 0px #333;
   border-radius: 21%;
   width: 15%;
  }
  /* Media Queries */
  @media (max-width: 768px) {
    .popup-content {
      flex-direction: column;
    }
  
    .left-section, .right-section {
      width: 100%;
    }
  
    .document-viewer {
      height: 300px;
    }
  
    .profile-image {
      width: 20%;
    }
  }
  
  @media (max-width: 480px) {
    .left-section, .right-section {
      padding: 5px;
    }
  
    .popup-inputfeild-application {
      margin-bottom: 5px;
    }
  
    .valt-number-input-feild {
      padding: 6px;
    }
  
    .document-viewer {
      height: 200px;
    }
  
    .profile-image {
      width: 30%;
    }
  }
  