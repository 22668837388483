@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ManropeRegular.ttf");
  font-family: "Manrope";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ManropeMedium.ttf");
  font-family: "Manrope";
  font-weight: 500;
}
