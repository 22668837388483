
  .input-feild-popupagnet::placeholder{
    color: #c3c3c3;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #c3c3c3;
  }
  .input-feild-popupagnet{
    border-bottom: 1px solid #c3c3c3;
  }
  input[type='text'], input[type='password'], input[type='number'], textarea{
    /* border-bottom: 1px solid #c3c3c3; */
    /* padding-bottom: 10px; */
  }
  [type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="search"]:focus, [type="checkbox"]:focus, [type="radio"]:focus, [multiple]:focus, textarea:focus, select:focus{
    /* border-bottom: 1px solid black !important;
    padding-bottom: 10px; */
    outline: none;
  }
  .no-data-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5em ;
  }