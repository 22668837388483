.second-row-customer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.top-custorm-width{
    width: 90%;
}
.h5-class-top-class,
.paragprh-customer-com {
  white-space: nowrap;      
  overflow: hidden;         
  text-overflow: ellipsis;  
}

.top-custorm-width {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.h5-class-top-class{
    font-size: 14px;
}
.tope-customer-com-main{
    width: 100%;
  margin: auto;
  background-color: #f7f7f7;
  padding: 15px 15px;
  border-radius: 11px;
  border: 0.5px solid #747eef;

}
.paragprh-customer-com{
    color: #3F3F3F;
    font-family: "Manrope", sans-serif;
    font-weight: 300;
    font-size: 0.7em;
    margin-bottom: 0;
    padding-top: 8px;
    padding: 0;
}
.heading-sub-customer-com{
    font-family: General Sans!important;
    font-size: 14px;
    font-weight: 500;
}
.gap-btw-colm{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}
.dropdown-header{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 5px !important;
    padding: 10px 7px !important;
    transition: background-color 0.3s;
  }
  
  .dropdown-header.open {
    display: flex;
    padding: 10px 7px;
    border-radius: 5px;
    align-items: center;
  }
  
  .dropdown-arrow {
    margin-left: auto;
    transition: transform 0.3s;
    font-size: 24px;
  }
  
  .dropdown-list {
    list-style: none;
    padding: 0px 25px;
    margin: 0;
}
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dropdown-item:hover,
  .dropdown-item.active {
    background-color: #6200ea;
    color:red
}
