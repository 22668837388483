@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.input-feild-custom{
    font-size: 17px;
    font-family: 'General Sans', sans-serif;
    font-weight: 200;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px; 
    border: none ;   
    border-bottom: 1px solid #d9dbed;

}

.input-feild-custom:focus{
   border-color: white;
    --tw-ring-color:transparent !important
}
.label-input {
  
    font-family: "Manrope", sans-serif;
    color: #d9dbed;
    
    font-size: 15px;
    font-weight: 600;
    transition: all 0.3s ease;
  }
  
  .label-input.focused {
    color: white;
  }
  .input-feild-custom::placeholder{
    color: #c7cae1d6;
  }
