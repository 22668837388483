.receipt-container {
  font-family: "Manrope", sans-serif;
  }
  .modal-body{
    padding-top: 0px !important;
  }
  .barcode {
    width: 100%;
    text-align: center;
    height: 7em;
  }
  
  .receipt-details {
    margin-bottom: 20px;
  }
  
  .receipt-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .receipt-label {
    font-weight: 400;
    font-size: 12px;
    font-family: "Manrope", sans-serif;
  }
  
  .receipt-value {
    text-align: right;
    font-family: General Sans!important;
    font-size: 13px;
    font-weight: 500;
  }
  
  .receipt-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .total-payment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .currency {
    font-size: 9px;
  }
  
  .download-btn {
    background-color: #7a5af8;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
  }
  .main-div-all-cutomers-com{
    background-color: #f7f7f7;
    padding: 15px 15px;
    border-radius: 11px;
    margin-top: 0.7em;
    border: 0.5px solid #747eef;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .colm-div-customer-com{
    width: 33%;
  }
  .forcefully-threedot{
    top: 1.2em !important;
    right: 10px !important;
    cursor: pointer;
  }
  .css-3bmhjh-MuiPaper-root-MuiPopover-paper{
    border-radius: 8px !important;
    border: 0.5px solid #747eef !important;
    padding: 10px !important;
    background-color: #f7f7f7 !important;
  }
  
  .dashboard-customer-details{
    width: 78%;
    background-color: white;
    padding: 15px 15px;
    border-radius: 11px;
    margin-top: 0.7em;
    box-shadow: 0px 0px 2px 0px #afafaf;
    margin-top: 1em;
  }
  .details-popup{
    cursor: pointer;
  }
  .addtion-adding{
    flex: 1 1 calc(50% - 16px); /* Adjust width and spacing */
    box-sizing: border-box;
    margin-top: 0 !important;
  }
  .div-customer-details-main{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
  margin-top: 0.8em;
  gap: 10px; /* Adjust spacing between items */
  }
  .booking-div-customer-div{
    background-color: #f7f7f7;
    padding: 15px 15px;
    border-radius: 11px;
    margin-top: 0.7em;
    border: 0.5px solid #747eef !important;
    position: relative;
  }
  .image-abousulte-delete{
    position: absolute;
    top: 15%;
    right: 25px;
    width: 7%;
    cursor: pointer;
  }
  /* .css-knqc4i-MuiDialogActions-root{
    width: 95% !important;
    margin: auto !important;
  } */
  .paymanet-div-super-admin{
    width: 20%;
  }
  .second-payment-div{
    width: 80%;
    display: flex;
    justify-content: end;
    gap: 10px;
  }
  .css-ag6wxc-MuiButtonBase-root-MuiIconButton-root{
    margin-right: 10px !important;
  }
  .css-cyxlny-MuiPaper-root-MuiDialog-paper{
    width: 55% !important;
  }
  .barcode{
    width: 100%;
  }
  .css-knqc4i-MuiDialogActions-root{
width: 95% !important;
margin: auto !important;
  }
  .css-16q3cfu-MuiPaper-root-MuiDialog-paper{
    width: auto !important;
    padding: 0 !important;
  }
  :where(.css-dev-only-do-not-override-m4timi).ant-modal .ant-modal-footer >.ant-btn+.ant-btn{
    background-color: #747eef !important;
  }
  /* [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled){
    background-color: #747eef !important;
  } */
  .topcustomerdata{
    width: 27%;
    object-fit: cover;
    height: 30px;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 1px #333;
  }