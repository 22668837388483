.colm-first{
    width: 47% ;
    /* padding:50px; */

}
.row{
    display: flex;
    justify-content:space-between;
    align-items: center;
    height: 53em;
    /* width: 95%; */
    gap: 28px !important;
    /* padding: 0px 70px; */
    margin: 0 !important;
}
.overlyClassName{
    position: absolute;
    z-index: -1;
    top: 0;

}
.error-text{
  color: black;
  font-size: 10px;
  font-family: 'General Sans';
}
.error-paragrhp{
  color: black;
  text-align: left;
  font-weight: 300;
  font-size: 12px;
  margin: 0;
  padding-bottom: 15px;
  font-family: 'General Sans', sans-serif;

}
.innerDiv-form{
   background-color: #747EEF;
    width: 80%;
    margin: auto;
    padding: 16px 16px 16px 20px;
    border-radius: 6px;
    padding-bottom: 30px;
    /* height: 31em; */
}.h1-heaing{
    font-size: 40px;
    font-family: 'General Sans', sans-serif;
    color: white;
    margin: 0;
    padding: 0;
    padding-bottom: 20px;
    font-weight: 500;
    text-align: left;
}
.gapmargin{margin-top: 15px;}
.fade-in {
    animation: fadeIn 0.5s ease forwards;
    opacity: 0;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  /* OTPComponent.css */

.otp-container {
    background-color: #747EEF;
    padding: 20px;
    border-radius: 10px;
    color: white;
    text-align: center;
  
    width: 80%;
    margin: auto;
    padding: 16px 16px 16px 20px;
    position: relative;
    padding-bottom: 30px;
  }
  
  .otp-input-container {
    justify-content:flex-start;
    margin-top: 2em;
    margin-bottom: 2em;
    display: flex;
    gap: 20px;
  
  }
  .paragraph-otp{
    font-family: "Manrope", sans-serif;
    color: white;
    font-weight: 200;
    font-size: 14px;
    text-align: left;
  }
 
  .otp-input {
    margin: 0 5px;
    /* padding: 10px !important    ; */
    border-radius: 10px !important;
    width: 50px;
    font-size: 30px !important;
    text-align: center;
    color: white;
    height: 2em;
    font-family: 'General Sans', sans-serif;
    background-color: #747EEF;
    border: 1px solid white !important; 
    
  }
  
  .submit-btn {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .fade-in {
    animation: fadeIn 0.5s ease forwards;
    opacity: 0;
  }
  .addtionopara{
    margin-bottom: 40px !important;
  }
  /* .btn-custom-optm{
    position: absolute;
    bottom: 4em;
    width: 20em !important;
    left: 0;
        transform: translate(15%, 80%);
  } */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .addbussiness-detsilsx{
    /* width: 60% !important; */
    margin: auto;
     margin-top: 10em;
    margin-bottom: 5em; 
  }
  