
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .select {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid black;
    border-radius: 0;
  }
  .select:focus{
    border-bottom: 1px solid black;
  }
  .textarea {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid black;
    border-radius: 0;
  }
  .textarea:focus{
    border-bottom: 1px solid black;
  }
  
  .button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #7b63f3;
    color: white;
    cursor: pointer;
  }
  