.image-pro-companies{
    width: 3em;
    height: 2.5em;
    object-fit: cover;
    border-radius: 7px;
}
.colm-statis-detsils-company{
    width: 50%;
}
.destils-row-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 15px;
}
