  @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  
}

.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Light dark background */
  display: flex;
  justify-content: center;
  align-items: center;
}


.popup input {
  display: block;
  margin-bottom: 10px;
  text-align: left;
  border: none;
  color: black;
  padding: 9px 0px;
  border-bottom: 1px solid #c3c3c3;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.popup h2 {
  margin-bottom: 22px;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: 22px;
}

.popup input::placeholder {
  color: #c3c3c3;
  font-weight: 400;
  font-size: 16px;
}

.popup input:focus {
  border-bottom: 1.5px solid black;
}

.btn-left,
.btn-right {
  padding: 5px;
  border: 1px solid black;
  color: black;
  font-family: 'Manrope', sans-serif;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  width: 200px;
}

.btn-right {
  border: 1px solid #747eef;
  color: white;
  background-color: #747eef;
}

.row-popup {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .popup {
    width: 90%;
  }

  .btn-left,
  .btn-right {
    width: 50%;
  }

  .row-popup {
    gap: 25px;
  }

  .popup {
    height: auto;
  }
}

.label-popup-seller {
  font-family: 'Manrope', sans-serif;
  margin-bottom: 5px;
}

.map-container {
  margin-top: 20px;
}

.close-icon {
  cursor: pointer;
}
