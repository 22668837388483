.chat-app {
    display: flex;
    height: 100vh;
    background-color: #f1f2fd;
    /* padding-top: 20px;
    padding-bottom: 20px; */
  }
  
  .active-conversations,
  .current-chat,
  .interaction-history {
    padding: 20px;
    /* overflow-y: auto; */
    margin-top: 20px;
    
    margin-bottom: 20px;

  }
  .chat-active-conversation-scroll{
    height: 26em;
    overflow: auto;
  }
  
  .active-conversations {
    width: 25%;
    background-color: #f1f2fd;
    /* border-right: 1px solid #ddd; */
  }
  
  .current-chat {
    width: 50%;
    background-color: #fff;
    border-radius: 15px;
    border: 0.5px solid #999;
  }
  
  .interaction-history {
    overflow: scroll;
    height: 40em;
    width: 25%;
    background-color: white;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: 0.5px solid #747eef;
    /* border-left: 1px solid #ddd; */
  }
  
  .conversation {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px;
    border: 0.5px solid #747eef;
  }
  
  .conversation.active {
    background-color: #e2e6ea;
  }
  
  .conversation:hover {
    background-color: #e2e6ea;
  }
  .addtion-styling-text{
    padding-top: 10px !important;
    padding-bottom: 20px !important;
  }
  
  /* .messages {
    padding: 10px 0;
    height: calc(100% - 100px); 
    overflow-y: auto;
  } */
  
  /* .message {
    margin-bottom: 10px;
    position: relative;
  } */
  
  .message.received {
    background-color: #747eef;
    padding: 10px;
    border-radius: 7px;
    border: 0.5px solid #747eef;
    color: white;
}
  /* .time-chat-application{
    font-family: "Manrope", sans-serif;

  } */
  .message.sent {
    align-self: flex-end;
    background-color: #f7f7f7;
    color: black;
    padding: 10px;
    border-radius: 5px;
    border: 0.5px solid #747eef;
  }
  
  .message span {
    display: block;
    font-size: 0.7em;
    color: black;
    margin-top: 5px;
  }
  
  .chat-input {
    display: flex;
    margin-top: 20px;
    align-items: center;
    border: 0.5px solid #747EEF;
    border-radius: 9px;
    /* padding-right: 10px; */

  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
    border-radius: 5px 0 0 5px;
  }
  /* [type="text"]:focus{
    border-bottom: 0px solid white !important;
  } */
  .chat-input input::placeholder{
    color:black
  }

  
  .history-item {
    background-color: #f7f7f7;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 0.5px solid #747eef;
  }
  .paragph-heading-status-heaing{
    border: 0.5px solid #747eef;
    color: #747eef;
    padding: 2px 7px;
    border-radius: 4px;
    font-size: 9px;
    font-family: "Manrope", sans-serif;
  }
  .status {
    color: #009B10;
    border: 1px solid #009B10;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    width: min-content;
    font-family: "Manrope", sans-serif;

  }
  
  .details,
  .date-time {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }
  .colm-div-width{
    width: 50%;
    text-align: left;
  }
  .messages {
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    height: calc(100% - 100px); 
    overflow-y: auto;
  }
  
  .message {
    display: flex;
    flex-direction: column;
    max-width: 60%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    word-wrap: break-word;
  }
  
  .agent-message {
    font-family: "Manrope", sans-serif;
    background-color: #f1f1f1; /* Light gray for agent */
    align-self: flex-start; /* Align agent messages to the left */
    border-radius: 10px 10px 10px 0; /* Rounded corners */
    font-size: 13px;
  }
  
  .customer-message {
    background-color: #747eef;
    font-size: 13px;
    color: white;
    font-family: "Manrope", sans-serif;
    align-self: flex-end; /* Align customer messages to the right */
    border-radius: 10px 10px 0 10px; /* Rounded corners */
  }
  
  .time-chat-application {
    font-size: 0.8rem;
    font-family: "Manrope", sans-serif;
    color: gray;
    margin-top: 5px;
    text-align: right; /* Align time to the right for customers */
  }
  .send-button-agent-send{
    padding: 10px 20px 13px;
    border: none;
    background-color: #747eef;
    color: white;
    border-radius: 0 8px 8px 0px;
  }
  