.input-feild-dic-icon{
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid black;
    border-radius: 6px;
    padding: 6px 10px;
    width: 50%;
}
.flex{
    display: flex !important;
}
.btn-job-histoiuyr{
    width: 50%;
    text-align: end;
}
.input-feild-class-job-history::placeholder{
    color: black;
    font-weight: 200;
    font-size: 13px;
}
.input-feild-class-job-history:focus{
    border: none;
    box-shadow: none;
}
.icon-serach-box-input{
    font-size: 18px;
    color: black;
}
.input-feild-class-job-history{
    color: black;
    font-weight: 200;
    font-size: 13px;
}
.responsive-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
  }
  .paragrph-paynet{
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    font-size: 0.8em;
  }
  .responsive-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
  }
  
  .paddingRight{
    padding-right: 2em !important;
    width: 90% !important;
  }
  .view-delete-btn{
    width: 10%;
    background-color: #f7f7f7;
    padding: 18px 16px;
    border-radius: 11px;
    border: 0.5px solid #747eef;
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .image-delete-view-details{
    width: 40%;
  }
  .width{
    width: 88% !important;
  }
  .customer-card-skeleton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 210px; /* Width of the card */
    padding: 16px;
    border-radius: 12px;
    margin-top: 0.2em;
    padding-top: 0;
    border: 1px solid #e0e0e0; /* Adjust border color as needed */
    background-color: #747eef9c; /* Adjust background color as needed */
  }
  
  .avatar-skeleton {
    margin-bottom: 8px;
  }
  
  .info-skeleton {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .status-spent-skeleton {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
  .responsive-container{
    margin-top: 1.2em;
  }
  .css-ekeie0 {
  width: 52%  !important;
  margin: auto !important;
  }
  