.mainDiv-all-sideBar{
 display: flex;
padding-bottom:10em;
background: #f1f2fd;
}

.main-heading2{
  font-family: General Sans!important;
  font-size: 1.2em;
  font-weight: 450;
  padding-left: 10px;
}
.orders {
  width: 100%;
  margin: auto;
  background-color: white;
  padding: 15px 15px;
  border-radius: 11px;
  margin-top: 0.7em;
  box-shadow: 0px 0px 2px 0px #afafaf;
  }
  .latest-reviews{
    background-color: white;
    padding: 15px 17px;
    border-radius: 11px;
    margin-top: 0.7em;
    box-shadow: 0px 0px 2px 0px #afafaf;
  }
  .order-list {
    display: flex;
    flex-direction: column;
  }
  
  .order-item {
    background: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    margin-top: 10px;
  }
  .dashboard {
    gap: 18px;
    display: flex;
    justify-content: space-between;
  }
  
  .stats {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    margin-bottom: 20px;
  }
  
  .stat-box {
    background: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .reviews {
    margin-top: 20px;
  }
  
  .review-item {
    background: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .review-item p {
    margin: 5px 0;
  }
  .div-sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 250px; /* Adjust this width as needed */
    /* z-index: 1000; */
    background: #f1f2fd;

  }
  
  .div-Dashbaord {
    margin-left: 250px; /* This should match the width of your sidebar */
    padding: 20px;
    width: calc(100% - 250px); /* Adjust to account for the sidebar width */
    min-height: 100vh;
    height: auto;
  }
  .h1-all-heading{
    font-family: General Sans!important;
    font-size: 1.4em;
    font-weight: 450;
  }
  .styles-div-text{
width: 30%;
  }
  .styles-div{
    width: 70%;
  }
  .review-box{
    padding: 15px 20px;
    border-radius: 11px;
    margin-top: 0.7em;
    background-color: #f7f7f7;
    border: 1px solid #747eef;
  }
  .heading-h5-later{
    font-family: General Sans!important;
    font-size: 1em;
    font-weight: 500;
  }
  .paragph-heading{
    color: #3F3F3F;
    font-family: "Manrope", sans-serif;
    font-weight: 300;
    font-size: 0.8em;
    margin-bottom: 0;
    padding-top: 8px;
    padding: 0;
  }
  .addtion-adding{
    color: black !important;
    font-size: 0.9em !important;
  
  }
  .div-rewie-main{
    display: flex;
    justify-content: space-between;
  }
  .replyicons{
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .replybtn{
    font-family: General Sans!important;
    font-weight: 400;
    font-size: 1em;
    color: black;
    background-color: #f7f7f7 !important;
  }
  .invoice-border{
    background-color: white;
    border-radius: 12px;
    padding: 20px 20px;
    box-shadow: 0px 0px 2px 0px #afafaf;
    margin-top: 1em;
  }
  .row-details-order{
    display: flex;
    gap: 12px;
    /* justify-content: space-between; */
    flex-wrap: wrap;
  }
  .styles-div-order{
    width: 100%;
  }
  .styles-div-order-sellar{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 1em;
    width: 100%;

  }
  .seller-shop-item {
    flex: 1 1 32%;
    max-width: 32%;
    box-sizing: border-box;
}
  .lightopcity-div{
    border: 1px solid #747eef;
    background-color: #Dee1fb;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 24.9em;
  }
  .add-seller-shop{
    font-family: General Sans!important;
    font-size: 17px;
    color: black;
   
  }
  .icons-seller{
    font-size: 15px;
  }