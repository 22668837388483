/* #contained-modal-title-vcenter{
    font-family: General Sans!important;
}
.paragrph-logut{
    font-size: 14px;
    color: black;
    font-weight: 500;
    font-family: General Sans!important;
    text-align: center;
    margin-top: 1em;

}
.image-logut-div{
    width: 100%;
    object-fit: contain;
    text-align: center;

}

.modal.show .modal-dialog{
    display: flex;
    width: 40em !important;
    justify-content: center;
    z-index: 99;
} */
.image-logout{
    width: 25%;
    height: auto;
    margin: 20px 0;
  
}
.h2-heading-main-mai{
    font-family: General Sans!important;
    font-size: 20px;
    font-weight: 500;
}
#classname-id-modal{
    width: 45% !important;
}
.are-logout-btn{
   font-size: 13px;
   font-family: General Sans!important;
   color: black;
   text-align: center;
}
.modal-footer-logut{
justify-content: space-between;
display: flex;
align-items: center;
margin-top: 10px;
}