.invoice-search-icon-div{
    background-color: white;
    color: black;
    position: relative;
    padding: 6px 15px;
    border-radius: 50px;
    margin-right: 10px;
    font-family: 'General Sans', sans-serif;
    box-shadow: 0px 0px 2px 0px #afafaf;
    font-size: 13px;
    outline: none;

}
.invoice-search-icon-div:focus{
    outline: none;
    border: none;
    --tw-ring-color:#747EEF
}
.invoice-search-icon-div::placeholder{
    color: black;
    font-size: 13px;
}
.icon-postion-div-inoice{
position: relative;

}
.main-inove-div-search{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
}